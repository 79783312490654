<template>
    <v-container>
        <v-row justify="center" class="py-5">
            <h1 class="display-1 font-weight-light">Create Account</h1>
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card tile elevation="4" class="pa-5" id="create-account-form">
                        <v-form v-model="accountForm" @submit="createAccount" onSubmit="return false;" @keyup.enter.native="createAccount">
                            <v-row justify="center" class="pb-2">
                                <p class="title font-weight-light">Account Information</p>
                            </v-row>
                            <v-row class="px-5">
                                <v-select
                                    v-model="accountType"
                                    :items="['Enterprise','Personal']"
                                    label="Account type"
                                    default="Enterprise"
                                    :rules="accountTypeRules"
                                    outlined
                                    required
                                ></v-select>
                            </v-row>
                            <v-row justify="center" class="px-5">
                                <v-text-field
                                v-model="accountName"
                                label="Company Name"
                                :rules="accountNameRules"
                                required
                                outlined
                                autofocus>
                                </v-text-field>
                            </v-row>
                            <!--
                            <v-row justify="center" class="px-5">
                                <v-text-field
                                    v-model="name"
                                    label="Your name"
                                    :rules="nameRules"
                                    required
                                    outlined>
                                ></v-text-field>
                            </v-row>
                            <v-row justify="center" class="px-5">
                                <v-text-field
                                    v-model="email"
                                    label="Your email"
                                    :rules="emailRules"
                                    required
                                    outlined
                                ></v-text-field>
                            </v-row>
                            -->
                            <!--
                            <v-row justify="center" class="pb-2">
                                <p class="title font-weight-light">Billing Information</p>
                            </v-row>
                            <v-row justify="center" class="px-5">
                                <v-text-field
                                v-model = "address"
                                label="Company Address"
                                :rules="addressRules"
                                required
                                outlined
                                ref="addressInputRef">
                                </v-text-field>
                            </v-row>
                            <v-row justify="center" class="px-5">
                                <v-text-field
                                v-model = "city"
                                label="City"
                                :rules="cityRules"
                                outlined
                                required>
                                </v-text-field>
                            </v-row>
                            <v-row justify="center">
                                <v-col class="px-5">
                                    <v-text-field
                                    v-model = "state"
                                        label="State"
                                        :rules="stateRules"
                                        outlined
                                        required>
                                    </v-text-field>
                                </v-col>
                                <v-col class="px-5">
                                    <v-text-field
                                        v-model = "zipCode"
                                        label="Zip Code"
                                        :rules="zipCodeRules"
                                        outlined
                                        required>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5">
                                <v-combobox
                                    v-model="defaultCountry"
                                    :items="countries"
                                    label="Country"
                                    default="United States"
                                    :rules="countryRules"
                                    outlined
                                    required
                                ></v-combobox>
                            </v-row>
                            -->
                            <v-row justify="center" class="pt-3 pb-5">
                                <v-btn tile elevation="4" class="blue white--text" @click="createAccount" :disabled="!accountForm">Continue</v-btn>
                            </v-row>
                        </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { /* mapGetters, */ mapState } from 'vuex';
import { isValidName, isValidEmail, compact } from '@/sdk/input';

export default {
    // components: {
    // },
    data() {
        return {
            isPaymentError: false,
            isAccountCreateError: false,
            isSubscribed: false,
            isBilling: false,
            accountForm: false,
            billingForm: false,
            stripeForm: false,
            invoiceForm: false,
            isCreditCardFormComplete: null,
            defaultCountry: 'United States',
            accountName: null,
            accountType: null,
            name: null,
            email: null,
            accountId: null,
            address: null,
            city: null,
            state: null,
            zipCode: null,
            countries: [
                'United States',
            ],
            invoiceEmailAddress: null,
            activeTabIndex: 0,
            accountNameRules: [
                (v) => (v && compact(v).length > 0) || 'Enter the name of the organization',
                // (v) => !v || !this.compactEnterpriseAccountNames.includes(compact(v)) || 'You already have an enterprise account with a similar name',
            ],
            nameRules: [
                (v) => isValidName(v) || 'Enter your name',
            ],
            emailRules: [
                (v) => isValidEmail(v) || 'Enter an email address to receive account notifications',
            ],
            invoiceEmailRules: [
                (v) => isValidEmail(v) || 'Enter an email address tor receive billing invoices',
            ],
            addressRules: [
                (v) => (v && compact(v).length > 0) || 'Enter address',
            ],
            cityRules: [
                (v) => (v && compact(v).length > 0) || 'Enter city',
            ],
            stateRules: [
                (v) => (v && compact(v).length > 0) || 'Enter state',
            ],
            zipCodeRules: [
                (v) => (v && compact(v).length > 0) || 'Enter zip code',
            ],
            countryRules: [
                (v) => (v && compact(v).length > 0) || 'Enter country',
            ],
            accountTypeRules: [
                (v) => (v && compact(v).length > 0) || 'Select account type',
            ],
        };
    },

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
        }),
        // ...mapGetters({
        // currentAccount: 'account',
        // enterpriseAccountList: 'enterpriseAccountList',
        // }),
        // compactEnterpriseAccountNames() {
        // return this.enterpriseAccountList.map((item) => compact(item.name));
        // },
    },

    watch: {
        isReady(value, oldValue) {
            console.log('subscribeEnterprise: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
    },

    methods: {
        init() {
            if (this.currentAccount.name) {
                this.name = this.currentAccount.name;
            }
            if (this.currentAccount.email) {
                this.email = this.currentAccount.email;
            }
        },
        async createAccount() {
            this.$store.commit('loading', { createAccount: true });
            // const response = await loginshield.stripe.createSubscription({
            // stripeToken: result.token.id, subscriptionType: 'Enterprise', accountName: accountName, name, email, address,
            // });
            const { isCreated, id: accountId } = await this.$client.account.create({
                name: this.accountName,
                type: this.accountType,
            });
            console.log(`create account result: created ${isCreated} account id ${accountId}`);
            this.$store.commit('loading', { createAccount: false });
            if (isCreated && accountId) {
                // redirect to view account page for the new account
                this.$router.push({ path: '/report/view', query: { id: 'account', accountId } }); // for example http://localhost:7519/report/view?id=account&accountId=WPuiHgr1lF1rn2rxJSkjrQ%3D%3D
            } else {
                this.isAccountCreateError = true;
            }
        },
    },
    created() {
    },
    mounted() {
        console.log('mounted');
        if (this.isReady) {
            this.init();
        }
    },
};
</script>

<style scoped>
    .StripeElement {
        box-sizing: border-box;

        height: 40px;

        padding: 10px 12px;

        border: 1px solid transparent;
        border-radius: 4px;
        background-color: white;

        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }
</style>
